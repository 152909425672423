/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useState } from "react";
import { graphql } from "gatsby";
import Button from "~components/Button";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const ContactPage = ({ data, location }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { frontmatter } = data.markdownRemark;

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="contact-page w-full relative">
        <section className="w-full relative block pt-32 xs:pt-20 pb-48">
          <article className="grid">
            <h1 className="animation-appear-down-slow animation-delay-1 grid-end-12 mt-12 mb-16 xs:mb-10 pr-16 xs:pr-0 pl-16 xs:pl-0 f2">
              {frontmatter.title}
            </h1>

            <div className="animation-appear-left-slow animation-delay-3 grid-end-6 xs:grid-end-12 pr-16 xs:pr-0 pl-16 xs:pl-0">
              <p className="b1">Ask us a question or just say hi</p>
              <p className="mt-2 xs:mt-0 b1 font-medium">
                <a href="mailto:hello@parlortea.com">hello@parlortea.com</a>
              </p>
            </div>

            <div className="animation-appear-right-slow animation-delay-3 grid-end-6 xs:grid-end-12 pr-16 xs:pr-0 pl-16 xs:pl-0">
              <p className="xs:mt-8 xs:mb-6 b1">
                Please fill in the following fields
              </p>

              <form className="form w-full relative block pt-2">
                <input
                  className="w-0 h-0 absolute opacity-0 pointer-events-none"
                  type="submit"
                />

                <input
                  className="w-full h-10 relative block mt-4 px-1 xs:px-0 border-b-black b1"
                  placeholder="Name*"
                  readOnly={submitted || submitting}
                  required
                  type="text"
                />

                <input
                  className="w-full h-10 relative block mt-4 px-1 xs:px-0 border-b-black b1"
                  placeholder="Email*"
                  readOnly={submitted || submitting}
                  required
                  type="email"
                />

                <input
                  className="w-full h-10 relative block mt-4 px-1 xs:px-0 border-b-black b1"
                  placeholder="Order No"
                  readOnly={submitted || submitting}
                  required
                  type="text"
                />

                <input
                  className="w-full h-10 relative block mt-4 px-1 xs:px-0 border-b-black b1"
                  placeholder="Country"
                  readOnly={submitted || submitting}
                  required
                  type="text"
                />

                <input
                  className="w-full h-10 relative block mt-4 px-1 xs:px-0 border-b-black b1"
                  placeholder="Subject*"
                  readOnly={submitted || submitting}
                  required
                  type="text"
                />

                <textarea
                  className="w-full h-48 relative block mt-8 p-2 border-black b1"
                  placeholder="Message*"
                  readOnly={submitted || submitting}
                  required
                />

                <Button
                  className="xs:w-full h-12 relative block mt-4 px-24 cursor-pointer border-black b1"
                  color="black"
                  transparent
                >
                  <span className="b1">Submit</span>
                </Button>
              </form>
            </div>
          </article>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
  }
`;
